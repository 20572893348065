import React, { useState, useEffect } from "react";
import "./WeekView.css";
import { CloseCircleFilled } from "@ant-design/icons";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import TokenService from "../tokenService";
import api from "../api";
import MyDay_Guidelines from "../assets/files/GuidelinesMyDay.pdf";
import { usePrompt } from "../hooks/router-hook";
import { isFirefox, isSafari, isIE } from "react-device-detect";
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";

export default function WeekView() {
  const MAX_HR_PER_DAY = 16;
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const [isDataLoading, setIsDataLoading] = useState(true);
  var [currentYear, setCurrentYear] = useState(moment().year());
  var [selectedWeek, setselectedWeek] = useState(
    moment().year() + "-W" + zeroPad(moment().isoWeek(), 2)
  );
  var [weekNumber, setweekNumber] = useState(moment().isoWeek());
  var [timesheetCodes, setTimesheetCodes] = useState([]);
  var [rowData, setrowData] = useState([]);
  var [weekData, setweekData] = useState([]);
  var [weekTotal, setweekTotal] = useState(0);
  const [commentText, setcommentText] = useState("");
  const [currentActiveCell, setcurrentActiveCell] = useState(null);
  const [selectedproject, setSelectedproject] = useState(null);
  var [disableFutureEntry, setdisableFutureEntry] = useState(false);
  const navigate = useNavigate();
  let userDetail;
  const dateFormat = "DD MMM YYYY";
  const [isDataUnsaved, setisDataUnsaved] = useState(false);
  let userNavigateMessage =
    "Unsaved data will be lost. Do you still want to continue?";
  var [timesheetRecords, settimesheetRecords] = useState([
    {
      selectedTimesheetCode: null,
      workNum: "",
      rowData: [],
      rowTotal: 0,
      rowNum: 1,
      isDuplicate: false,
      isDirty: false,
    },
  ]);

  if (TokenService.getUser()) {
    userDetail = TokenService.getUser();
  }

  usePrompt(userNavigateMessage, isDataUnsaved);

  useEffect(() => {
    const loadtimesheetCodes = async () => {
      let userId = TokenService.getUserId();
      let url = `/timesheetCodes?page=1&limit=1000&status=All&userId=${userId}`;
      const response = await api.get(url);
      if (response && response.data && response.data.TimesheetCodes)
        setOptions(response.data.TimesheetCodes);
    };

    const setOptions = (codes) => {
      var temptimesheetCodes = codes.map((element) => {
        return {
          value: element._id,
          label: element.timeSheetCode,
          isDisabled: element.status === "Inactive",
        };
      });
      setTimesheetCodes(temptimesheetCodes);
    };
    loadtimesheetCodes();
  }, [navigate]);

  let deleteTimesheetRow = async (index, timesheetRecord) => {
    let text = "Do you want to delete timesheet data ?";
    if (
      timesheetRecord &&
      timesheetRecord.selectedTimesheetCode &&
      timesheetRecord.workNum
    ) {
      if (window.confirm(text) === true) {
        timesheetRecords.splice(index, 1);
        deleteTimesheetData(timesheetRecord);
      }
    } else {
      timesheetRecords.splice(index, 1);
      deleteTimesheetData(timesheetRecord);
    }
  };

  let deleteTimesheetData = async (timesheetRecord, showMessage = true) => {
    settimesheetRecords(timesheetRecords);
    setcommentText("");
    if (timesheetRecord.timesheetId) {
      const res2 = await api.post("/timesheetRow/delete", {
        timesheetId: timesheetRecord.timesheetId,
      });

      if (res2.status !== 200) throw Error(res2.data.message);
      else {
        const res = await api.post("/timesheet/delete", {
          timesheetId: timesheetRecord.timesheetId,
        });

        if (res.status !== 200) throw Error(res.data.message);
      }
    }

    if (showMessage) {
      let weektot = 0;
      weekData.forEach((weekday) => {
        let columnTotal = 0;
        weekday.columnTotal = 0;
        timesheetRecords.forEach((element) => {
          element.rowData.forEach((row) => {
            if (
              row.fulldate === weekday.fulldate &&
              row.value &&
              row.value > 0
            ) {
              columnTotal += row.value;
            } else {
              columnTotal += 0;
            }
            weekday.columnTotal = parseFloat(columnTotal.toFixed(2));
          });
        });
        weektot += parseFloat(weekday.columnTotal);
      });
      let finalval = parseFloat(weektot.toFixed(2));
      setweekData(weekData);
      setweekTotal(finalval);
      setcurrentActiveCell({ date: "", value: "", comment: "" });
      toast.success("Timesheet row deleted successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  let addTimesheetRow = () => {
    let len = timesheetRecords.length - 1;
    timesheetRecords.push({
      selectedTimesheetCode: null,
      workNum: "",
      rowData: JSON.parse(JSON.stringify(rowData)),
      rowTotal: 0,
      rowNum: timesheetRecords[len].rowNum + 1,
      isDuplicate: false,
      isDirty: false,
    });
    setcurrentActiveCell({ date: "", value: "", comment: "" });
  };

  let copyTimesheetData = async () => {
    let lastweekNumber = weekNumber - 1;
    let year = currentYear;
    var noofweeks = moment().year(currentYear).isoWeeksInYear();
    if (!(weekNumber <= noofweeks && weekNumber > 1)) {
      year = currentYear - 1;
      var totalWeeks = moment().year(year).isoWeeksInYear();
      lastweekNumber = totalWeeks;
    }

    let userId = userDetail ? userDetail._id : "";
    let url = `/timesheet?weekNumber=${lastweekNumber}&userId=${userId}&year=${year}`;
    const response = await api.get(url);
    let timesheets = response.data.Timesheets;

    let text =
      "Existing data if any, will be overwritten. Do you want to continue?";

    if (timesheets && timesheets.length > 0 && window.confirm(text) === true) {
      timesheetRecords.pop();
      timesheetRecords.forEach(async (element) => {
        await deleteTimesheetData(element, false);
      });
      let processedRecords = timesheets.length;
      timesheetRecords = [];
      timesheets.forEach(async (timesheet) => {
        let prevselectedTimesheetCode = timesheetCodes.find(
          (item) => item.value === timesheet.timesheetCodeId
        );

        if (prevselectedTimesheetCode && prevselectedTimesheetCode.isDisabled)
          processedRecords -= 1;
        else if (
          prevselectedTimesheetCode &&
          !prevselectedTimesheetCode.isDisabled
        ) {
          let len = timesheetRecords.length;
          let dbrowData = [...rowData];
          let rowTot = 0;
          if (timesheet.timesheetrows.length) {
            timesheet.timesheetrows.forEach((timesheetRow) => {
              if (timesheetRow.hours && timesheetRow.hours.length)
                rowTot += parseFloat(timesheetRow.hours);
              let day = moment(timesheetRow.timesheetDate).format("ddd");
              let row = dbrowData.find((item) => item.day === day);
              let index = dbrowData.indexOf(row);
              let oldRow = dbrowData[index];
              dbrowData[index] = {
                fulldate: oldRow.fulldate,
                date: oldRow.date,
                day: oldRow.day,
                value:
                  timesheetRow.hours && timesheetRow.hours.length
                    ? parseFloat(timesheetRow.hours)
                    : "",
                comment: timesheetRow.comment,
                isDirty: true,
              };
            });
          }

          timesheetRecords.push({
            selectedTimesheetCode: timesheetCodes.find(
              (item) => item.value === timesheet.timesheetCodeId
            ),
            workNum: timesheet.workNumber,
            rowData: JSON.parse(JSON.stringify(dbrowData)),
            rowTotal: rowTot,
            rowNum: len + 1,
            isDuplicate: false,
            isDirty: true,
          });
          processedRecords -= 1;
        }

        if (processedRecords === 0) {
          addTimesheetRow();
          settimesheetRecords(timesheetRecords);
          let weektot = 0;
          weekData.forEach((weekday) => {
            let columnTotal = 0;
            weekday.columnTotal = 0;
            timesheetRecords.forEach((element) => {
              element.rowData.forEach((row) => {
                if (row.day === weekday.day && row.value && row.value > 0) {
                  columnTotal += row.value;
                } else {
                  columnTotal += 0;
                }
                weekday.columnTotal = parseFloat(columnTotal.toFixed(2));
              });
            });
            weektot += parseFloat(weekday.columnTotal);
          });
          let finalval = parseFloat(weektot.toFixed(2));
          setweekData(weekData);
          setweekTotal(finalval);
          setisDataUnsaved(true);
          toast.info("Only active timesheet code/project data is copied.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else if (!timesheets || timesheets.length === 0) {
      toast.info("No timesheet data found for last week.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  let saveTimesheetData = () => {
    setisDataUnsaved(false);
    let userId = userDetail._id;
    let savedCount = 0;
    let allProcessed = timesheetRecords.length;
    timesheetRecords.forEach(async (timesheetrecord) => {
      let validCount = timesheetrecord.rowData.filter(
        (row) => row && row.value && parseFloat(row.value) > 0
      ).length;
      if (
        !timesheetrecord.isDuplicate &&
        (timesheetrecord.selectedTimesheetCode || timesheetrecord.workNum)
      )
        timesheetrecord.isDuplicate = validCount === 0;
      if (
        validCount === 0 ||
        timesheetrecord.isDuplicate ||
        !timesheetrecord.selectedTimesheetCode ||
        !timesheetrecord.workNum
      ) {
        allProcessed -= 1;
        if (allProcessed === 0 && savedCount > 0) {
          toast.info(
            "Timesheet data saved partially due to incorrect entries.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else if (allProcessed === 0 && savedCount === 0) {
          toast.error(
            "Timesheet data not saved due to incorrect/blank entries.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        return;
      }

      if (timesheetrecord.isDirty) {
        const res = await api.post("/timesheet", {
          timesheetId: timesheetrecord.timesheetId
            ? timesheetrecord.timesheetId
            : "",
          timesheetCodeId: timesheetrecord.selectedTimesheetCode.value,
          userId: userId,
          weekNumber: weekNumber,
          year: currentYear,
          workNumber: timesheetrecord.workNum,
        });
        if (res.status !== 200) throw Error(res.data.message);
        timesheetrecord.timesheetId = res.data._id;
        timesheetrecord.isDirty = false;
      }

      let timesheetRows = [];
      timesheetrecord.rowData.forEach((row) => {
        if (!row.isDirty || (!row.value && !row.timesheetRowId)) return;
        timesheetRows.push({
          timesheetDate: new Date(row.fulldate),
          timesheetId: timesheetrecord.timesheetId,
          hours: row.value,
          comment: row.comment,
        });
      });

      if (timesheetRows && timesheetRows.length > 0) {
        const res2 = await api.post("/timesheetRow", {
          timesheetRows: timesheetRows,
        });
        if (res2.status !== 200) throw Error(res2.data.message);
        else {
          savedCount += 1;
          timesheetrecord.rowData.forEach((row) => {
            row.isDirty = false;
          });
        }
      } else savedCount += 1;

      allProcessed -= 1;
      if (savedCount === timesheetRecords.length - 1 && allProcessed === 0) {
        toast.success("Timesheet data saved successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (allProcessed === 0 && savedCount > 0) {
        toast.info("Timesheet data saved partially due to incorrect entries.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const onprojectCodeChangeHandler = (selectedOption, tsrecord, index) => {
    let validCount = tsrecord.rowData.filter(
      (row) => row && row.value && parseFloat(row.value) > 0
    ).length;

    if (
      validCount > 0 &&
      selectedOption &&
      tsrecord.selectedTimesheetCode &&
      selectedOption &&
      tsrecord.selectedTimesheetCode !== selectedOption &&
      tsrecord.workNum
    ) {
      setisDataUnsaved(true);
      tsrecord.isDirty = true;
    }

    tsrecord.selectedTimesheetCode = selectedOption;
    onworkNumHandler(tsrecord);
    setcurrentActiveCell({ date: "", value: "", comment: "" });
    setSelectedproject(tsrecord);

    if (
      tsrecord.selectedTimesheetCode &&
      tsrecord.workNum &&
      timesheetRecords.length === index + 1
    ) {
      tsrecord.isDirty = true;
      addTimesheetRow();
    }
  };

  const onworkNumChangeHandler = (value, tsrecord, index) => {
    let validCount = tsrecord.rowData.filter(
      (row) => row && row.value && parseFloat(row.value) > 0
    ).length;

    if (
      validCount > 0 &&
      tsrecord.selectedTimesheetCode &&
      value &&
      tsrecord.workNum !== value
    ) {
      setisDataUnsaved(true);
      tsrecord.isDirty = true;
    }
    tsrecord.workNum = value.replace(/ /g, "");
    setcurrentActiveCell(tsrecord.workNum);
    onworkNumHandler(tsrecord);
    setSelectedproject(tsrecord);
    if (
      tsrecord.selectedTimesheetCode &&
      tsrecord.workNum &&
      timesheetRecords.length === index + 1
    ) {
      addTimesheetRow();
      tsrecord.isDirty = true;
    }
  };

  const onworkNumHandler = (tsrecord) => {
    timesheetRecords.forEach((timesheetRecord) => {
      timesheetRecord.isDuplicate = false;
      var duplicateRecords = timesheetRecords.filter(
        (item) =>
          item.rowNum !== timesheetRecord.rowNum &&
          item.selectedTimesheetCode &&
          timesheetRecord.selectedTimesheetCode &&
          item.selectedTimesheetCode ===
            timesheetRecord.selectedTimesheetCode &&
          item.workNum === timesheetRecord.workNum
      );
      if (duplicateRecords && duplicateRecords.length) {
        timesheetRecord.isDuplicate = true;
        duplicateRecords.forEach((drecord) => {
          drecord.isDuplicate = true;
        });
      }
    });
  };

  const handleCommentTextChange = (e) => {
    if (
      currentActiveCell &&
      (currentActiveCell.value > 0 || currentActiveCell.comment)
    ) {
      if (currentActiveCell.comment !== e.target.value) {
        setisDataUnsaved(true);
        currentActiveCell.isDirty = true;
      }
      currentActiveCell.comment = e.target.value;
      setcommentText(e.target.value);
    }
  };

  let getTimesheetData = async (timesheets, temptimesheetCodes) => {
    setIsDataLoading(true);
    timesheetRecords = [];
    timesheets.forEach(async (timesheet) => {
      let dbrowData = [...rowData];
      let rowTot = 0;
      if (timesheet.timesheetrows.length) {
        timesheet.timesheetrows.forEach((timesheetRow) => {
          if (timesheetRow.hours && timesheetRow.hours.length)
            rowTot += parseFloat(timesheetRow.hours);
          let fullDate = moment(timesheetRow.timesheetDate).format(dateFormat);
          let row = dbrowData.find((item) => item.fulldate === fullDate);
          let index = dbrowData.indexOf(row);

          dbrowData[index] = {
            timesheetRowId: timesheetRow._id,
            fulldate: moment(timesheetRow.timesheetDate).format(dateFormat),
            date: moment(timesheetRow.timesheetDate).format("DD"),
            day: moment(timesheetRow.timesheetDate).format("ddd"),
            value:
              timesheetRow.hours && timesheetRow.hours.length
                ? parseFloat(timesheetRow.hours)
                : "",
            comment: timesheetRow.comment,
          };
        });
      }
      let len = timesheetRecords.length;

      timesheetRecords.push({
        timesheetId: timesheet._id,
        selectedTimesheetCode: temptimesheetCodes.find(
          (item) => item.value === timesheet.timesheetCodeId
        ),
        workNum: timesheet.workNumber,
        rowData: JSON.parse(JSON.stringify(dbrowData)),
        rowTotal: rowTot,
        rowNum: len + 1,
        isDuplicate: false,
        isDirty: false,
      });

      if (timesheets.length === timesheetRecords.length) {
        addTimesheetRow();
        settimesheetRecords(timesheetRecords);
        let weektot = 0;
        weekData.forEach((weekday) => {
          let columnTotal = 0;
          weekday.columnTotal = 0;
          timesheetRecords.forEach((element) => {
            element.rowData.forEach((row) => {
              if (
                row.fulldate === weekday.fulldate &&
                row.value &&
                row.value > 0
              ) {
                columnTotal += row.value;
              } else {
                columnTotal += 0;
              }
              weekday.columnTotal = parseFloat(columnTotal.toFixed(2));
            });
          });
          weektot += parseFloat(weekday.columnTotal);
        });
        let finalval = parseFloat(weektot.toFixed(2));
        setweekData(weekData);
        setweekTotal(finalval);
        setIsDataLoading(false);
      }
    });
  };

  let updateWeekDetails = async () => {
    if (!weekData.length && !rowData.length) {
      var startDate = moment()
        .isoWeek(weekNumber)
        .isoWeekYear(currentYear)
        .startOf("isoweek");
      var endDate = moment()
        .isoWeek(weekNumber)
        .isoWeekYear(currentYear)
        .startOf("isoweek")
        .add(7, "days");

      for (var m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
        weekData.push({
          fulldate: m.format(dateFormat),
          day: m.format("ddd"),
          month: m.format("MMM"),
          date: m.format("DD"),
          columnTotal: 0,
        });

        rowData.push({
          fulldate: m.format(dateFormat),
          day: m.format("ddd"),
          date: m.format("DD"),
          value: "",
          comment: "",
          isDirty: false,
        });
      }
      if (!disableFutureEntry) {
        let userId = userDetail ? userDetail._id : "";
        let url = `/timesheet?weekNumber=${weekNumber}&userId=${userId}&year=${currentYear}`;
        const response = await api.get(url);
        if (
          response &&
          response.data &&
          response.data.Timesheets &&
          response.data.Timesheets.length > 0
        ) {
          if (timesheetCodes.length === 0) {
            let userId = TokenService.getUserId();
            let baseURL = `/timesheetCodes?page=1&limit=1000&status=All&userId=${userId}`;
            const res = await api.get(baseURL);
            var temptimesheetCodes = res.data.TimesheetCodes.map((element) => {
              return {
                value: element._id,
                label: element.timeSheetCode,
                isDisabled: element.status === "Inactive",
              };
            });
            setTimesheetCodes(temptimesheetCodes);
            getTimesheetData(response.data.Timesheets, temptimesheetCodes);
          } else {
            getTimesheetData(response.data.Timesheets, timesheetCodes);
          }
        } else {
          timesheetRecords.forEach((timesheetrecord) => {
            timesheetrecord.rowData = JSON.parse(JSON.stringify(rowData));
            timesheetrecord.selectedTimesheetCode = null;
            timesheetrecord.workNum = "";
            timesheetrecord.rowTotal = 0;
          });

          if (timesheetRecords.length === 0) {
            timesheetRecords.push({
              selectedTimesheetCode: null,
              workNum: "",
              rowData: JSON.parse(JSON.stringify(rowData)),
              rowTotal: 0,
              rowNum: 1,
              isDuplicate: false,
              isDirty: false,
            });
          }

          weekData.forEach((weekday) => {
            weekday.columnTotal = 0;
          });
          setcommentText("Hello");
          settimesheetRecords(timesheetRecords);
          setcommentText("");
          setweekTotal(0);
          setIsDataLoading(false);
        }
      } else {
        timesheetRecords = [];
        timesheetRecords.push({
          selectedTimesheetCode: null,
          workNum: "",
          rowData: JSON.parse(JSON.stringify(rowData)),
          rowTotal: 0,
          rowNum: 1,
          isDuplicate: false,
          isDirty: false,
        });
        settimesheetRecords(timesheetRecords);
        setweekData(weekData);
        setweekTotal(0);
        setcommentText("");
        setIsDataLoading(false);
      }
    }
  };

  let handleChange = (event, rowvalue, index, fromOnChange) => {
    if (event.target.value === "" && rowvalue.value === "") {
      calculateTotalTime(index, fromOnChange);
      return;
    }

    const fixed = parseFloat(event.target.value).toFixed(2).toString();
    if (fixed.length < parseFloat(event.target.value).toString().length)
      event.target.value = fixed;

    if (!fromOnChange && event.target.value && event.target.value > 0)
      event.target.value = roundUpValue(event.target.value);
    if (event.target.value === "") event.target.value = 0;
    if (
      event.target.value &&
      rowvalue.value !== parseFloat(event.target.value)
    ) {
      rowvalue.isDirty = true;
      setisDataUnsaved(true);
    }

    rowvalue.value =
      event.target.value && event.target.value > 0
        ? parseFloat(event.target.value)
        : "";

    calculateTotalTime(index, fromOnChange);
  };

  let calculateTotalTime = (index, fromOnChange) => {
    if (!fromOnChange) {
      var total = 0;
      timesheetRecords[index].rowData.forEach((element) => {
        if (element.value && element.value > 0) {
          total += element.value;
        }
      });

      if (timesheetRecords[index].isDuplicate && total > 0) {
        timesheetRecords[index].isDuplicate = false;
      }

      let newtimesheetRecords = [...timesheetRecords]; // copying the old datas array
      newtimesheetRecords[index].rowTotal = parseFloat(total.toFixed(2)); // replace record with whatever you want to change it to
      settimesheetRecords(newtimesheetRecords);
    }
    let weektot = 0;
    weekData.forEach((weekday) => {
      let columnTotal = 0;
      timesheetRecords.forEach((element) => {
        element.rowData.forEach((row) => {
          if (row.fulldate === weekday.fulldate && row.value && row.value > 0) {
            columnTotal += row.value;
          } else {
            columnTotal += 0;
          }
          weekday.columnTotal = parseFloat(columnTotal.toFixed(2));
        });
      });
      weektot += parseFloat(weekday.columnTotal);
    });
    let finalval = parseFloat(weektot.toFixed(2));
    setweekData(weekData);
    setweekTotal(finalval);
  };

  let roundUpValue = (value) => {
    if (value < 0.25) return 0.25;
    if (value > MAX_HR_PER_DAY) return MAX_HR_PER_DAY;
    if (!Number.isInteger(parseFloat(value)))
      value = (Math.round(value * 4) / 4).toFixed(2);
    return value;
  };

  let updateErrorMessage = (weekNum, year) => {
    var now = moment();
    var startDate = moment().year(year).isoWeek(weekNum).startOf("isoweek");
    setdisableFutureEntry(moment(startDate).isAfter(now));
  };

  let clearData = () => {
    setcurrentActiveCell(null);
    setSelectedproject(null);
    setrowData([]);
    setweekData([]);
    setweekTotal(0);
    settimesheetRecords([]);
    setcommentText("");
    setisDataUnsaved(false);
  };

  let validateUnSavedData = () => {
    if (isDataUnsaved) {
      if (window.confirm(userNavigateMessage)) {
        return true;
      } else return false;
    } else return true;
  };

  let handleselectedWeek = (value) => {
    if (validateUnSavedData() && value && value.length > 0) {
      var str = value.split("-W");
      if (parseInt(str[0]) >= 2000 && parseInt(str[0]) <= 2050) {
        setselectedWeek(value);

        if (parseInt(str[0]) !== currentYear && parseInt(str[0]) >= 2000)
          setCurrentYear(parseInt(str[0]));
        var noofweeks = moment().year(currentYear).isoWeeksInYear();
        if (parseInt(str[1]) < 1) setweekNumber(1);
        else if (parseInt(str[1]) > noofweeks) setweekNumber(noofweeks);
        else setweekNumber(parseInt(str[1]));
        clearData();
        updateErrorMessage(str[1], str[0]);
      }
    }
  };

  let prevWeekClicked = () => {
    if (validateUnSavedData()) {
      var noofweeks = moment().year(currentYear).isoWeeksInYear();
      if (weekNumber <= noofweeks && weekNumber > 1) {
        let newWeekNum = weekNumber - 1;
        setweekNumber(newWeekNum);
        setselectedWeek(currentYear + "-W" + zeroPad(newWeekNum, 2));
      } else {
        let newYearNum = currentYear - 1;
        setCurrentYear(newYearNum);
        var totalWeeks = moment().year(newYearNum).isoWeeksInYear();
        setweekNumber(totalWeeks);
        setselectedWeek(newYearNum + "-W" + totalWeeks);
      }
      clearData();
      updateErrorMessage(weekNumber - 1, currentYear);
    }
  };

  let nextWeekClicked = () => {
    if (validateUnSavedData()) {
      var noofweeks = moment().year(currentYear).isoWeeksInYear();
      if (weekNumber < noofweeks && weekNumber > 0) {
        let newWeekNum = weekNumber + 1;
        setweekNumber(newWeekNum);
        setselectedWeek(currentYear + "-W" + zeroPad(newWeekNum, 2));
      } else if (weekNumber >= noofweeks) {
        let newYearNum = currentYear + 1;
        setCurrentYear(newYearNum);
        setweekNumber(1);
        setselectedWeek(newYearNum + "-W01");
      }
      clearData();
      updateErrorMessage(weekNumber + 1, currentYear);
    }
  };

  useEffect(() => {
    updateWeekDetails();
  }, [weekNumber, currentYear]);

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "block",
    }),
  };

  return (
    <>
      {!isDataLoading ? (
        <Container fluid>
          <Row>
            <Col>
              <div className="col-sm-6">
                {(isFirefox || isIE || isSafari) && (
                  <nav className="nav nav-pills week-nav">
                    <div className="nav-link active btn-ts">
                      Week
                      <h6>
                        <button
                          name="prevWeek"
                          onClick={prevWeekClicked}
                          className="prev"
                          title="Previous Week"
                        >
                          &lt;
                        </button>
                        {weekNumber}

                        <button
                          name="nextWeek"
                          onClick={nextWeekClicked}
                          className="next"
                          title="Next Week"
                        >
                          &gt;
                        </button>
                      </h6>
                      <h6>{currentYear}</h6>
                    </div>
                  </nav>
                )}

                {!isFirefox && !isIE && !isSafari && (
                  <>
                    <button
                      name="prevWeek"
                      onClick={prevWeekClicked}
                      className="prev-cal nav-cal"
                    >
                      &lt;
                    </button>
                    <input
                      autoFocus
                      type="week"
                      title="Select a Week"
                      onChange={(e) => handleselectedWeek(e.target.value)}
                      value={selectedWeek}
                      min="2000-W01"
                      max="2050-W52"
                      name="calendar"
                      className="cal"
                      required
                    ></input>
                    <button
                      name="nextWeek"
                      onClick={nextWeekClicked}
                      className="next-cal nav-cal"
                    >
                      &gt;
                    </button>
                    <InfoCircleFilled
                      className="next-cal info-btn"
                      title="Use Up and Down arrow keys to navigate weeks"
                    ></InfoCircleFilled>
                  </>
                )}
              </div>
            </Col>
            <Col className="action-buttons">
              <ul className="list-btns">
                <li>
                  <a
                    href={MyDay_Guidelines}
                    download="GuidelinesMyDay.pdf"
                    className="btn-weekView"
                  >
                    Timesheet Guidelines
                  </a>
                  {!disableFutureEntry && (
                    <div className="save-btn-li">
                      {weekNumber === moment().isoWeek() && (
                        <button
                          className="btn btn-ts btn-weekView"
                          title="Copy from last week"
                          onClick={(e) => copyTimesheetData()}
                        >
                          Copy from last week
                        </button>
                      )}
                      <button
                        className="btn btn-ts btn-weekView"
                        title="Save"
                        onClick={(e) => saveTimesheetData()}
                        disabled={!isDataUnsaved}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col lg={true}>
              <table id="timesheetTbl">
                <thead>
                  <tr>
                    <th style={{ width: "16vw" }}>
                      <div className="thlink">
                        <span className="heading-font">PROJECT</span>
                      </div>
                    </th>
                    <th style={{ width: "8vw" }}>
                      <div className="thlink">
                        <span className="heading-font">WORK #</span>
                      </div>
                    </th>
                    {weekData.map((item) => (
                      <th key={item.date} className="wkTbl">
                        <div className="datelink">
                          <span className="smalltitle heading-font">
                            {item.date} {item.month}
                          </span>
                          <br />
                          <span className="smalltitle heading-font">
                            {item.day}
                          </span>
                        </div>
                      </th>
                    ))}
                    <th className="wkTbl">
                      <span className="datelink heading-font">TOTAL TIME</span>
                    </th>
                    <th className="wkTblAction">
                      <div className="datelink"></div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!isDataLoading &&
                    timesheetRecords.map((tsrecord, index) => (
                      <tr key={tsrecord.rowNum}>
                        <td style={{ width: "16vw" }}>
                          <Select
                            className={
                              tsrecord && tsrecord.isDuplicate
                                ? "duplicateWeekNum"
                                : ""
                            }
                            styles={dropdownStyle}
                            options={_.sortBy(timesheetCodes, "label")}
                            isClearable={true}
                            isDisabled={disableFutureEntry}
                            value={tsrecord.selectedTimesheetCode}
                            onChange={(e) =>
                              onprojectCodeChangeHandler(e, tsrecord, index)
                            }
                          />
                        </td>
                        <td
                          style={{ width: "8vw" }}
                          title={
                            tsrecord && tsrecord.isDuplicate
                              ? "Please enter different Project &  Work # combination."
                              : ""
                          }
                        >
                          <input
                            className={
                              tsrecord && tsrecord.isDuplicate
                                ? "weekInput duplicateWeekNum"
                                : "weekInput"
                            }
                            title={
                              tsrecord && tsrecord.isDuplicate
                                ? "Please enter unique Work # & Project combination."
                                : ""
                            }
                            value={tsrecord.workNum}
                            maxLength="10"
                            type="text"
                            disabled={disableFutureEntry}
                            onChange={(e) =>
                              onworkNumChangeHandler(
                                e.target.value,
                                tsrecord,
                                index
                              )
                            }
                            onFocus={() => {
                              onworkNumHandler(tsrecord);
                              setcurrentActiveCell(null);
                              setSelectedproject(null);
                              setcommentText("");
                            }}
                            required
                          ></input>
                        </td>
                        {tsrecord.rowData.map((item) => (
                          <td key={item.date} className="wkTbl">
                            <div>
                              <input
                                type="number"
                                min="0"
                                className={`${
                                  item.comment ? "filledComment " : ""
                                }
                            ${
                              item.day.toUpperCase() === "SAT" ||
                              item.day.toUpperCase() === "SUN"
                                ? "weekend-bg "
                                : ""
                            }
                            ${
                              item === currentActiveCell
                                ? "highlightedComment"
                                : ""
                            }`}
                                max={MAX_HR_PER_DAY}
                                value={item.value}
                                disabled={
                                  disableFutureEntry ||
                                  !tsrecord.selectedTimesheetCode ||
                                  !tsrecord.workNum
                                }
                                onChange={(e) =>
                                  handleChange(e, item, index, true)
                                }
                                onBlur={(e) => {
                                  handleChange(e, item, index, false);
                                }}
                                onFocus={() => {
                                  setcurrentActiveCell(item);
                                  setSelectedproject(tsrecord);
                                  setcommentText(item.comment);
                                }}
                              ></input>
                            </div>
                          </td>
                        ))}
                        <td className="wkTbl">
                          <div>
                            <input
                              type="number"
                              value={tsrecord.rowTotal}
                              readOnly
                            ></input>
                          </div>
                        </td>
                        <td className="wkTblAction">
                          {!disableFutureEntry &&
                            tsrecord.selectedTimesheetCode &&
                            tsrecord.workNum && (
                              <button
                                className="btn-delete"
                                size="sm"
                                title="Delete Timesheet Row"
                                onClick={(e) =>
                                  deleteTimesheetRow(index, tsrecord)
                                }
                              >
                                <CloseCircleFilled />
                              </button>
                            )}
                        </td>
                      </tr>
                    ))}

                  {disableFutureEntry && (
                    <tr className="timesheet-error">
                      <td>
                        <label>
                          You cannot enter timesheet for future weeks.
                        </label>
                      </td>
                    </tr>
                  )}
                </tbody>

                <tfoot>
                  <tr style={{ marginBottom: "3px" }}>
                    <td
                      style={{ width: "30.65vw" }}
                      className="remarksText heading-font"
                    >
                      <h6>Total Hours</h6>
                    </td>
                    {weekData.map((item) => (
                      <td key={item.date} className="wkTblFoot">
                        <div className="totallink">
                          <span className="smalltitle blue-box">
                            {item.columnTotal}
                          </span>
                        </div>
                      </td>
                    ))}
                    <td className="wkTblFoot wkTotal">
                      <span>{weekTotal}</span>
                    </td>
                    <td className="wkTblAction"></td>
                  </tr>
                  <tr>
                    <td
                      style={{ width: "10.65vw" }}
                      className="remarksText heading-font"
                    >
                      <h6>Comments</h6>
                    </td>
                    <td
                      style={{ width: "20vw", marginLeft: "10px" }}
                      className="remarksText"
                    >
                      <span className="smalltitle">
                        {selectedproject &&
                        selectedproject.selectedTimesheetCode &&
                        selectedproject.selectedTimesheetCode.label &&
                        selectedproject.workNum &&
                        currentActiveCell &&
                        currentActiveCell.fulldate
                          ? selectedproject.selectedTimesheetCode.label +
                            "-" +
                            selectedproject.workNum
                          : ""}
                      </span>
                      <br />
                      <span className="smalltitle">
                        {selectedproject &&
                        selectedproject.selectedTimesheetCode &&
                        selectedproject.selectedTimesheetCode.label &&
                        selectedproject.workNum &&
                        currentActiveCell &&
                        currentActiveCell.fulldate
                          ? currentActiveCell.fulldate
                          : ""}
                      </span>
                    </td>
                    <td style={{ width: "44.8vw" }}>
                      <textarea
                        className={
                          currentActiveCell && currentActiveCell.date
                            ? "commentText highlightedComment"
                            : "commentText"
                        }
                        value={commentText}
                        onBlur={(e) => handleCommentTextChange(e)}
                        onChange={(e) => handleCommentTextChange(e)}
                        disabled={
                          disableFutureEntry ||
                          !currentActiveCell ||
                          !currentActiveCell.date
                        }
                      ></textarea>
                    </td>
                    <td style={{ width: "8.95vw" }}></td>
                  </tr>
                </tfoot>
              </table>
            </Col>
          </Row>

          <Row>
            <Col>
              <ToastContainer autoClose={1500} transition={Slide} limit={2} />
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="card-title">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
