import React from "react";
import { useMsal } from "@azure/msal-react";
import TokenService from "../tokenService";
/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    TokenService.removeUser();
    TokenService.removeRefreshToken();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  return (
    <button
      className="heading-font logoutBtn"
      onClick={() => handleLogout()}
      title="Sign Out"
    ></button>
  );
};
