import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate("/");

  return (
    <section className="card-title">
      <h5>You do not have access to the requested page.</h5>
      <button onClick={goBack} className="btn btn-ts">
        Go Back
      </button>
    </section>
  );
};

export default Unauthorized;
