import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../aadconfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };
  return (
    <button
      className="heading-font btn sign-in-btn btn-ts"
      onClick={() => handleLogin()}
    >
      Sign In
    </button>
  );
};
