import React, { useEffect, useState } from "react";
import ChangePageTitle from "./ChangePageTitle";
import TokenService from "../tokenService";
import api from "../api";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { MultiSelect } from "react-multi-select-component";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

async function getProjects(pageNum, pageSize, status) {
  let userId = TokenService.getUserId();
  let url = `/projects?page=${pageNum}&limit=${pageSize}&status=${status}&userId=${userId}`;
  const response = await api.get(url);
  return response && response.data;
}

async function getUsers(pageNum, pageSize, status) {
  let userId = TokenService.getUserId();
  var url = `/users?page=${pageNum}&limit=${pageSize}&status=${status}&userId=${userId}`;
  const response = await api.get(url);
  return response && response.data;
}

const Reports = () => {
  const [projects, setprojects] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [invoiceData, setinvoiceData] = useState([]);
  const [allReportData, setallReportData] = useState([]);
  const [csvFileName, setcsvFileName] = useState("");
  const [reportHeaders, setreportHeaders] = useState([]);
  const invoiceReportHeaders = [
    { label: "Employee ID", key: "EmployeeID" },
    { label: "Name", key: "EmployeeName" },
    { label: "Manager", key: "Manager" },
    { label: "Project", key: "ProjectName" },
    { label: "Account Code", key: "AccountCode" },
    { label: "Timesheet Code", key: "TimesheetCode" },
    { label: "Total Hours Logged", key: "TotalHours" },
  ];
  const [selectedProjects, setselectedProjects] = useState([]);
  var [timesheetCodes, setTimesheetCodes] = useState([]);
  const [allTimesheetCodes, setallTimesheetCodes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [selectedCodes, setselectedCodes] = useState([]);
  const [includeReportees, setincludeReportees] = useState(false);
  const [users, setusers] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [allUsers, setallUsers] = useState([]);

  const [state, setState] = useState({
    start: moment().startOf("month"),
    end: moment().endOf("month"),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
  };
  const label = start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");

  useEffect(() => {
    getProjects(1, 100, "Ongoing").then((json) => {
      if (json && json.Projects) {
        if (json.Projects.length > 0) {
          var _projects = json.Projects.map((element) => {
            return {
              value: element._id,
              label: element.name,
            };
          });
          setprojects(_projects);
        }
      }
    });

    getUsers(1, 200, "All").then((result) => {
      if (result && result.Users) {
        if (result.Users.length > 0) {
          setallUsers(result.Users);
          var _users = result.Users.map((element) => {
            return {
              value: element.employeeId,
              label: element.displayName,
            };
          });
          var sortedUsers = _.sortBy(_users, "label");
          setusers(sortedUsers);
        }
      }
    });

    const loadtimesheetCodes = async () => {
      let userId = TokenService.getUserId();
      let url = `/timesheetCodes?page=1&limit=1000&status=All&userId=${userId}`;
      const response = await api.get(url);
      if (response && response.data && response.data.TimesheetCodes) {
        setallTimesheetCodes(response.data.TimesheetCodes);
      }
    };

    loadtimesheetCodes();
  }, []);

  let applyFilters = async () => {
    setisLoading(true);
    setallReportData([]);
    setreportData([]);
    var projectIds = selectedProjects.map((element) => element.value);
    let filteredUsers = [];
    var userIds;
    if (includeReportees) {
      selectedUsers.forEach((element) => {
        let _users = allUsers.filter(
          (rd) =>
            rd.employeeId === element.value || rd.manager === element.label
        );
        filteredUsers = _.union(filteredUsers, _users);
      });
      userIds = filteredUsers.map((element) => element.employeeId);
    } else userIds = selectedUsers.map((element) => element.value);
    let url = `/reports?startDate=${new Date(
      moment(start).startOf("day")
    ).toUTCString()}&endDate=${new Date(
      moment(end).startOf("day")
    ).toUTCString()}&projectIds=${projectIds}&userIds=${userIds}`;
    let response = await api.get(url);
    setallReportData(response.data);

    response.data.forEach((row) => {
      row["EmployeeID"] = row._id.EmployeeID;
      if (row.TimesheetData.length > 0) {
        row.TimesheetData.forEach((element) => {
          row[moment(element.TimesheetDate).format("DD MMM YYYY")] =
            element.Hours;
        });
      }
    });
    if (selectedCodes.length > 0) getFilteredData(selectedCodes, response.data);
    else setreportData(response.data);
    setisLoading(false);
  };

  var filterfunc = function (data, selectedvalue) {
    let result = data.filter((dt) => dt.value === selectedvalue);
    return result;
  };

  let getTimesheetCodes = (projectIds) => {
    let tempTimesheetCodes = [];
    projectIds.forEach((projectId) => {
      let result = allTimesheetCodes.filter(
        (p) =>
          p.projectId === projectId && p.status === "Active" && !p.isDeleted
      );
      if (result && result.length > 0) {
        tempTimesheetCodes.push(...result);
      }
    });
    timesheetCodes = tempTimesheetCodes.map((element) => {
      return {
        value: element._id,
        label: element.timeSheetCode,
      };
    });
    if (selectedCodes && selectedCodes.length > 0) {
      let index = selectedCodes.length;
      while (index--) {
        let result = filterfunc(timesheetCodes, selectedCodes[index].value);
        if (result && result.length === 0) {
          selectedCodes.splice(index, 1);
        }
      }
    }
    setTimesheetCodes(timesheetCodes);
    ontimesheetCodeChange(selectedCodes, allReportData);
  };

  let ontimesheetCodeChange = (selectedItems, data) => {
    getFilteredData(selectedItems, data);
    setselectedCodes(selectedItems);
  };

  let onuserSelectionChange = (selectedItems) => {
    if (!selectedItems || selectedItems.length === 0)
      setincludeReportees(false);
    setselectedUsers(selectedItems);
  };

  let getFilteredData = (selectedTSCodes, data) => {
    if (selectedTSCodes.length === 0) {
      setreportData(data);
      return;
    } else {
      var filteredReportData = [];
      if (selectedTSCodes && selectedTSCodes.length > 0) {
        selectedTSCodes.forEach((element) => {
          let tempreportData = data.filter(
            (rd) => rd._id.TimesheetCodeId === element.value
          );
          filteredReportData.push(...tempreportData);
        });
        data = filteredReportData;
      }
      setreportData(filteredReportData);
    }
  };

  let onProjectChange = (selectedItems) => {
    if (selectedItems && selectedItems.length > 0) {
      var projectIds = selectedItems.map((element) => element.value);
      getTimesheetCodes(projectIds);
    } else {
      setselectedCodes([]);
      setTimesheetCodes([]);
    }
    setselectedProjects(selectedItems);
  };

  const columns = [
    {
      dataField: "_id.EmployeeID",
      text: "Employee ID",
      sort: true,
      align: "right",
      editable: false,
      headerClasses: "grid-header",
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "EmployeeName",
      text: "Name",
      headerClasses: "grid-header",
      sort: true,
      editable: false,
    },
    {
      dataField: "Manager",
      text: "Manager",
      headerClasses: "grid-header",
      sort: true,
      editable: false,
    },
    {
      dataField: "ProjectName",
      text: "Project",
      headerClasses: "grid-header",
      sort: true,
      editable: false,
    },
    {
      dataField: "TimesheetCode",
      text: "Timesheet Code",
      sort: true,
      headerClasses: "grid-header",
      editable: false,
    },
    {
      dataField: "AccountCode",
      text: "Account Code",
      sort: true,
      headerClasses: "grid-header",
      editable: false,
    },
    {
      dataField: "_id.WorkNumber",
      text: "Work #",
      sort: true,
      headerClasses: "grid-header",
      editable: false,
    },
    {
      dataField: "TotalHours",
      text: "Total Hours",
      sort: true,
      align: "right",
      editable: false,
      headerClasses: "grid-header",
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <table>
        <thead>
          <tr>
            {row.TimesheetData.map((item) => (
              <th key={item._id} className="reportTbl">
                {moment(item.TimesheetDate).format("DD MMM")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {row.TimesheetData.map((item) => (
              <td
                key={item._id}
                className={
                  item.Comment ? "filledComment reportTbl" : "reportTbl"
                }
                title={item.Comment}
              >
                {item.Hours}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    ),
    showExpandColumn: true,
  };

  const defaultSorted = [
    {
      dataField: "EmployeeName",
      order: "asc",
    },
  ];

  let onExportReportClick = () => {
    let tempReportHeaders = [
      { label: "Employee ID", key: "_id.EmployeeID" },
      { label: "Name", key: "EmployeeName" },
      { label: "Manager", key: "Manager" },
      { label: "Project", key: "ProjectName" },
      { label: "Account Code", key: "AccountCode" },
      { label: "Timesheet Code", key: "TimesheetCode" },
      { label: "Work #", key: "_id.WorkNumber" },
      { label: "Total Hours Logged", key: "TotalHours" },
    ];
    for (var m = moment(start); m.isBefore(moment(end)); m.add(1, "days")) {
      tempReportHeaders.push({
        label: moment(m).format("DD MMM YYYY"),
        key: moment(m).format("DD MMM YYYY"),
      });
    }
    setreportHeaders(tempReportHeaders);
    setcsvFileName(
      "ReportData_" + format(new Date(), "yyyyMMddHHmmss").toString() + ".csv"
    );
  };

  let onInvoiceDataClick = () => {
    const result = groupAndSum(
      reportData,
      [
        "EmployeeID",
        "EmployeeName",
        "TimesheetCode",
        "Manager",
        "ProjectName",
        "AccountCode",
      ],
      ["TotalHours"]
    );
    setinvoiceData(result);
    setcsvFileName(
      "InvoiceData_" + format(new Date(), "yyyyMMddHHmmss").toString() + ".csv"
    );
  };

  function groupAndSum(arr, groupKeys, sumKeys) {
    return Object.values(
      arr.reduce((acc, curr) => {
        const group = groupKeys.map((k) => curr[k]).join("-");
        acc[group] =
          acc[group] ||
          Object.fromEntries(
            groupKeys
              .map((k) => [k, curr[k]])
              .concat(sumKeys.map((k) => [k, 0]))
          );
        sumKeys.forEach((k) => (acc[group][k] += curr[k]));
        return acc;
      }, {})
    );
  }

  return (
    <>
      <ChangePageTitle pageTitle={`MyDay | Reports`} />
      <div className="page-margin container-fluid grid-padding">
        <div className="report-container row">
          <div className="col-4">
            <label htmlFor="SelectDateRange" className="col-35">
              Select Date Range
            </label>
            <div className="report-margin report-datepicker col-70">
              <DateRangePicker
                initialSettings={{
                  autoApply: true,
                  startDate: start.toDate(),
                  endDate: end.toDate(),
                  showCustomRangeLabel: false,
                  alwaysShowCalendars: true,
                  locale: {
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    firstDay: 1,
                  },
                  ranges: {
                    "Current Month": [
                      moment().startOf("month").toDate(),
                      moment().endOf("month").toDate(),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month").toDate(),
                      moment().subtract(1, "month").endOf("month").toDate(),
                    ],
                    "Current Week": [
                      moment().startOf("isoWeek").toDate(),
                      moment().endOf("isoWeek").toDate(),
                    ],
                    "Last Week": [
                      moment()
                        .subtract(1, "isoWeek")
                        .startOf("isoWeek")
                        .toDate(),
                      moment().subtract(1, "isoWeek").endOf("isoWeek").toDate(),
                    ],
                    "Last 7 Days": [
                      moment().subtract(6, "days").toDate(),
                      moment().toDate(),
                    ],
                  },
                }}
                onCallback={handleCallback}
              >
                <div
                  id="reportrange"
                  className="col-4"
                  style={{
                    background: "#fff",
                    cursor: "pointer",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}
                >
                  <i className="fa fa-calendar"></i>&nbsp;
                  <span>{label}</span> <i className="fa fa-caret-down"></i>
                </div>
              </DateRangePicker>
            </div>
          </div>

          <div className="col-3">
            <label htmlFor="project" className="col-15">
              Project
            </label>

            <MultiSelect
              options={projects}
              value={selectedProjects}
              onChange={(selectedItems) => {
                onProjectChange(selectedItems);
              }}
              labelledBy="Select"
              className="report-margin project-select col-80"
            />
          </div>

          <div className="col-3">
            <label htmlFor="user" className="col-15">
              User
            </label>
            <MultiSelect
              options={users}
              value={selectedUsers}
              onChange={(selectedItems) => {
                onuserSelectionChange(selectedItems);
              }}
              labelledBy="Select"
              className="report-margin project-select col-80"
            />
          </div>

          <div className="col-2">
            <input
              type="checkbox"
              id="includeMembers"
              className="report-chkbox"
              checked={includeReportees}
              disabled={!selectedUsers || selectedUsers.length === 0}
              onChange={(event) => setincludeReportees(event.target.checked)}
            ></input>
            <label
              htmlFor="includeMembers"
              className="report-margin report-lbl"
            >
              Include reportees
            </label>
          </div>
        </div>

        <div className="report-container row">
          <div className="col-4">
            <label htmlFor="timesheetCode" className="col-35">
              Timesheet Code
            </label>
            <MultiSelect
              options={_.sortBy(timesheetCodes, "label")}
              value={selectedCodes}
              onChange={(selectedItems) => {
                ontimesheetCodeChange(selectedItems, allReportData);
              }}
              labelledBy="Select"
              className="report-margin project-select col-70"
            />
          </div>

          <div className="col-8">
            <CSVLink
              data={_.sortBy(reportData, "EmployeeName")}
              headers={reportHeaders}
              filename={csvFileName}
              className={
                !reportData || reportData.length === 0
                  ? "btn actionButton report-margin reportButtons exportReportDisabled"
                  : "btn actionButton report-margin reportButtons"
              }
              onClick={onExportReportClick}
              tabIndex="-1"
            >
              Export Data
            </CSVLink>

            <CSVLink
              data={_.sortBy(invoiceData, "EmployeeName")}
              headers={invoiceReportHeaders}
              filename={csvFileName}
              className={
                !reportData || reportData.length === 0
                  ? "btn actionButton report-margin reportButtons exportReportDisabled"
                  : "btn actionButton report-margin reportButtons"
              }
              onClick={onInvoiceDataClick}
              tabIndex="-1"
            >
              Invoice Data
            </CSVLink>
            <button
              className="btn actionButton reportButtons report-margin"
              onClick={(e) => applyFilters()}
              disabled={
                !start ||
                !end ||
                selectedProjects.length === 0 ||
                selectedUsers.length === 0
              }
            >
              Apply Filters
            </button>
          </div>
        </div>

        <BootstrapTable
          bootstrap4
          keyField="TimesheetRowId"
          data={reportData}
          columns={columns}
          defaultSorted={defaultSorted}
          defaultSortDirection="asc"
          striped
          hover
          condensed
          expandRow={expandRow}
          noDataIndication={() =>
            !isLoading &&
            start &&
            end &&
            selectedUsers.length > 0 &&
            selectedProjects.length > 0 && <span>No data found...</span>
          }
        />
        {isLoading && (
          <div className="card-title">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </>
  );
};

export default Reports;
