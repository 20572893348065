import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import React from "react";
import EditProject from "./EditProject";
import { ListTimesheetCodes } from "./ListTimesheetCodes";
import { useLocation, Link } from "react-router-dom";
import { LeftCircleFilled } from "@ant-design/icons";
import ChangePageTitle from "./ChangePageTitle";

function ProjectDetails() {
  const location = useLocation();
  const { projectdata, closeModal } = location.state;
  return (
    <>
      <ChangePageTitle pageTitle={`MyDay | ${projectdata.name}`} />
      <div className="back-height">
        <Link to="/u/Projects" title="Go to Projects" className="btn-back">
          <LeftCircleFilled />
        </Link>
      </div>
      <div className="card-margin" id="project-tabs">
        <h2>{projectdata.name}</h2>
        <Tabs
          defaultActiveKey="details"
          className="mb-3"
          unmountOnExit={true}
          variant={"pills"}
        >
          <Tab eventKey="details" title="Details" className="project-modal">
            <EditProject projectdata={projectdata} onClose={closeModal} />
          </Tab>
          <Tab eventKey="timesheetCodes" title="Timesheet Codes">
            <ListTimesheetCodes projectdata={projectdata} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ProjectDetails;
