import React, { useState } from "react";
import api from "../api";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditTimesheetCode = ({ timesheetcodedata, close, projectStatus }) => {
  const [timesheetcode, setTimesheetcode] = useState({
    id: timesheetcodedata._id,
    description: timesheetcodedata.description,
    timeSheetCode: timesheetcodedata.timeSheetCode,
    accountCode: timesheetcodedata.accountCode,
    status: timesheetcodedata.status,
    projectId: timesheetcodedata.projectId,
    projectStatus: projectStatus,
  });

  const onChange = (e) => {
    setTimesheetcode({ ...timesheetcode, [e.target.name]: e.target.value });
  };

  const editHandler = async (e) => {
    if (
      !timesheetcode.description ||
      !timesheetcode.timeSheetCode ||
      !timesheetcode.accountCode ||
      !timesheetcode.status
    ) {
      return;
    }
    e.preventDefault();
    const { id, description, timeSheetCode, accountCode, status, projectId } =
      timesheetcode;

    const response = await api.put("/timesheetCodes", {
      id,
      description,
      timeSheetCode,
      accountCode,
      status,
      projectId,
    });
    if (response.status === 200) {
      toast.success("Timesheet code saved successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      close();
    }
  };

  return (
    <div className="form-container">
      <form method="POST" className="form">
        <div className="input-container">
          <label htmlFor="description" className="col-40">
            Description *
          </label>
          <textarea
            className="col-60"
            type="text"
            name="description"
            value={timesheetcode.description}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="timeSheetCode" className="col-40">
            Timesheet Code *
          </label>
          <input
            className="col-60"
            type="text"
            name="timeSheetCode"
            value={timesheetcode.timeSheetCode}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="accountCode" className="col-40">
            Account Code *
          </label>
          <input
            className="col-60"
            type="text"
            name="accountCode"
            value={timesheetcode.accountCode}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="status" className="col-40">
            Status *
          </label>
          <select
            className="col-60"
            name="status"
            value={timesheetcode.status}
            onChange={onChange}
            disabled={projectStatus === "Closed"}
            required
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>

        <div className="input-container">
          <button
            className="btn btn-success"
            type="submit"
            value="save"
            onClick={editHandler}
          >
            Save
          </button>
        </div>
      </form>
      <ToastContainer autoClose={2000} transition={Slide} />
    </div>
  );
};

export default EditTimesheetCode;
