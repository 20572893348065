import React from "react";
import { ListUsers } from "./components/ListUsers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./components/AuthRedirect";
import Timesheet from "./components/Timesheet";
import { ListCustomers } from "./components/ListCustomers";
import { ProjectList } from "./components/ProjectList";
import ProjectDetails from "./components/ProjectDetails";
import Unauthorized from "./components/Unauthorized";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Reports from "./components/Reports";

function App() {
  let authorizedRoles = [
    "Administrator",
    "Functional Manager",
    "Report Manager",
  ];
  let allRoles = [
    "Administrator",
    "Functional Manager",
    "User",
    "Report Manager",
  ];
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Auth />} />
        <Route path="/u/" element={<Layout />}>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route element={<RequireAuth allowedRoles={authorizedRoles} />}>
            <Route path="Employees" element={<ListUsers />} />
            <Route path="Customers" element={<ListCustomers />} />
            <Route path="Projects" element={<ProjectList />} />
            <Route path="ProjectDetails" element={<ProjectDetails />} />
            <Route path="Reports" element={<Reports />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={allRoles} />}>
            <Route path="Timesheet" element={<Timesheet />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
