import React, { useState, useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Header from "./Header";
import TokenService from "../tokenService";
import api from "../api";

async function getUserDetails(userName) {
  const response = await api.get(`/users/validateUser/${userName}`);
  if (response && response.data) {
    try {
      return response.data;
    } catch (error) {
      return null;
    }
  }
  return null;
}

export default function AuthRedirect() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const handleClose = () => {
    setShow(false);
    handleLogout();
  };

  useEffect(() => {
    if (isAuthenticated && TokenService.getUser()) {
      let userDetail = TokenService.getUser();
      getUserDetails(userDetail.userName).then((response) => {
        var user = response.user;
        if (response.refreshToken)
          TokenService.updateRefreshToken(response.refreshToken);
        if (user) TokenService.setUser(user);
        if (!user || (user && !user.isEnable && user.status === "Inactive")) {
          setShow(true);
          setShowHeader(false);
        } else {
          navigate("/u/Timesheet");
        }
      });
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    TokenService.removeUser();
    TokenService.removeRefreshToken();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  useEffect(() => {
    const eventId = instance.addEventCallback(
      (event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          getUserDetails(event.payload.account.username).then((response) => {
            if (response.refreshToken)
              TokenService.updateRefreshToken(response.refreshToken);
            var user = response.user;
            if (user && user.userName) TokenService.setUser(user);
            if (!user || (!user.isEnable && user.status === "Inactive")) {
              setShow(true);
              setShowHeader(false);
            } else {
              navigate("/u/Timesheet");
            }
          });
        }
      },
      (error) => {
        console.log("error", error);
      }
    );

    // returned function will be called on component unmount
    return () => {
      if (eventId) {
        instance.removeEventCallback(eventId);
      }
    };
  }, [instance, navigate]);

  return (
    <>
      {showHeader && <Header />}
      <Modal show={show} onHide={handleClose} keyboard="true">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Username or Password provided is incorrect. Please contact your
          administrator. You'll be redireted to Signout page.
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-ts" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
