import React, { useState } from "react";
import "../index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import api from "../api";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCustomer = (props) => {
  const [customer, setcustomer] = useState({
    name: "",
    address1: "",
    address2: "",
    address3: "",
    websiteUrl: "",
    startDate: "",
    endDate: "",
  });
  let name, value;
  const onChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setcustomer({ ...customer, [name]: value });
  };
  const postdata = async (e) => {
    if (
      !customer.name ||
      !customer.address1 ||
      !customer.websiteUrl ||
      !customer.startDate
    ) {
      return;
    }

    e.preventDefault();
    const {
      name,
      address1,
      address2,
      address3,
      websiteUrl,
      startDate,
      endDate,
    } = customer;

    const response = await api.post("/customers", {
      name,
      address1,
      address2,
      address3,
      websiteUrl,
      startDate,
      endDate,
    });
    if (response.status === 200) {
      toast.success("Customer data saved successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      props.onclose();
    }
  };

  const checkURL = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (e.target.value) {
      var url = e.target.value;
      if (!~url.indexOf("http")) {
        url = "http://" + url;
      }
      setcustomer({ ...customer, [name]: url });
    }
  };

  return (
    <div className="form-container">
      <form className="form">
        <div className="input-container">
          <label htmlFor="name" className="col-25">
            Customer *
          </label>
          <input
            type="text"
            name="name"
            value={customer.name}
            onChange={onChange}
            className="col-75"
            placeholder="Customer Name"
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="websiteUrl" className="col-25">
            Website URL *
          </label>
          <input
            type="url"
            name="websiteUrl"
            className="col-75"
            placeholder="Website"
            value={customer.websiteUrl}
            onBlur={(e) => checkURL(e)}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="address1" className="col-25">
            Address 1 *
          </label>
          <textarea
            className="col-75"
            name="address1"
            placeholder="Address 1"
            value={customer.address1}
            onChange={onChange}
            required
          ></textarea>
        </div>

        <div className="input-container">
          <label htmlFor="address2" className="col-25">
            Address 2
          </label>
          <textarea
            className="col-75"
            name="address2"
            placeholder="Address 2"
            value={customer.address2}
            onChange={onChange}
          ></textarea>
        </div>

        <div className="input-container">
          <label htmlFor="address3" className="col-25">
            Address 3
          </label>
          <textarea
            className="col-75"
            name="address3"
            placeholder="Address 3"
            value={customer.address3}
            onChange={onChange}
          ></textarea>
        </div>

        <div className="input-container">
          <label htmlFor="startDate" className="col-25">
            Start Date *
          </label>
          <input
            className="col-75"
            type="date"
            name="startDate"
            value={customer.startDate}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="endDate" className="col-25">
            End Date
          </label>
          <input
            className="col-75"
            type="date"
            name="endDate"
            min={customer.startDate}
            value={customer.endDate}
            onChange={onChange}
          />
        </div>

        <div className="input-container">
          <button
            className="btn btn-success"
            type="submit"
            onClick={postdata}
            value="save"
          >
            Save
          </button>
        </div>
      </form>
      <ToastContainer autoClose={2000} transition={Slide} />
    </div>
  );
};

export default AddCustomer;
