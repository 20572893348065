import React from "react";
import debounce from "lodash.debounce";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../index.css";
import AddTimesheetCode from "./AddTimesheetCode";
import { Modal } from "react-bootstrap";
import EditTimesheetCode from "./EditTimesheetCode";
import Spinner from "react-bootstrap/Spinner";
import { EditFilled, CloseCircleFilled } from "@ant-design/icons";
import api from "../api";
import TokenService from "../tokenService";
import { ToastContainer, toast, Slide } from "react-toastify";

var pageNum = 1;
const pageSize = 25;
var allDataLoaded = false;

var isReportManager = TokenService.checkReportManager();

async function callApi(pageNum, pageSize, status, projectId) {
  let userId = TokenService.getUserId();
  let url = `/timesheetCodes?page=${pageNum}&limit=${pageSize}&status=${status}&projectId=${projectId}&userId=${userId}`;
  const response = await api.get(url);
  return response.data;
}

var rowData = {};

async function TimesheetCodeById(id) {
  var response = await api.put(`/timesheetCodes/deleteTimesheetcodes`, {
    _id: id,
  });
  if (response.status === 405) {
    toast.error(response.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  return response;
}

export class ListTimesheetCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      allUsers: [],
      DataisLoaded: false,
      value: "Active",
      selectedTimesheetCode: {},
      showModal: false,
      projectId: props.projectdata._id,
      projectStatus: props.projectdata.status,
    };

    window.onscroll = debounce(() => {
      const { loadTimesheetCodes } = this;

      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >=
          document.documentElement.offsetHeight &&
        allDataLoaded === false
      ) {
        loadTimesheetCodes();
      }
    }, 100);
  }

  loadTimesheetCodes = () => {
    pageNum++;
    callApi(pageNum, pageSize, this.state.value, this.state.projectId).then(
      (data) => {
        if (data && data.TimesheetCodes) {
          if (data.TimesheetCodes.length < pageSize) {
            allDataLoaded = true;
          }
          if (data.TimesheetCodes.length > 0) {
            this.setState({
              timesheetCodes: [
                ...this.state.timesheetCodes,
                ...data.TimesheetCodes,
              ],
            });
          }
        }
      }
    );
  };

  componentDidMount() {
    callApi(pageNum, pageSize, this.state.value, this.state.projectId).then(
      (data) => {
        this.setState({
          timesheetCodes: data.TimesheetCodes,
          DataisLoaded: true,
        });
      }
    );
  }

  handleChange = (event) => {
    pageNum = 1;
    allDataLoaded = false;
    this.setState({ value: event.target.value });
    callApi(pageNum, pageSize, event.target.value, this.state.projectId).then(
      (data) => {
        if (data && data.TimesheetCodes) {
          if (data.TimesheetCodes.length < pageSize) {
            allDataLoaded = true;
          }
          if (data.TimesheetCodes.length > 0) {
            this.setState({ timesheetCodes: data.TimesheetCodes });
          } else this.setState({ timesheetCodes: [] });
        }
      }
    );
  };

  refreshData = () => {
    var newPageSize = this.state.timesheetCodes.length;
    if (newPageSize < pageSize) newPageSize = pageSize;
    callApi(1, newPageSize, this.state.value, this.state.projectId).then(
      (data) => {
        if (data && data.TimesheetCodes) {
          if (data.TimesheetCodes.length < pageSize) {
            allDataLoaded = true;
          }
          if (data.TimesheetCodes.length > 0) {
            this.setState({
              timesheetCodes: data.TimesheetCodes,
              DataisLoaded: true,
            });
          } else this.setState({ timesheetCodes: [] });
        }
      }
    );
  };

  handleModal = () => {
    this.setState({ showModal: false });
    this.refreshData();
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handlemodal(row) {
    rowData = row;
    this.setState({ showmodal: !this.state.showmodal });
  }

  handleEditModal = (row) => {
    this.setState({ selectedTimesheetCode: row });
    this.setState({ showModals: !this.state.showModals, row });
  };

  hideEditModals() {
    this.setState({ showModals: !this.state.showModals });
  }

  closeEditModal = () => {
    this.setState({ showModals: false });
    this.refreshData();
  };

  deleteTimesheetcodes(row) {
    TimesheetCodeById(row._id).then((result) => {
      if (result && result.status === 200) {
        this.refreshData();
        toast.success("Timsheet code deleted successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }

  render() {
    const { DataisLoaded, timesheetCodes } = this.state;
    if (!DataisLoaded)
      return (
        <div className="card-title">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );

    const actionButton = (cell, row) => {
      return (
        <div>
          <button
            className="btn-edit"
            size="sm"
            title="Edit Timesheet Code"
            onClick={() => this.handleEditModal(row)}
          >
            <EditFilled />
          </button>
          <button
            className="btn-delete"
            size="sm"
            title="Delete Timesheet Code"
            onClick={() => this.handlemodal(row)}
          >
            <CloseCircleFilled />
          </button>
        </div>
      );
    };

    const columns = [
      {
        dataField: "description",
        text: "Description",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "timeSheetCode",
        text: "Time Sheet Code",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "accountCode",
        text: "Account Code",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "actions",
        text: "Action",
        isDummyField: true,
        formatter: actionButton,
        headerClasses: "grid-header",
        headerStyle: { width: "8%" },
        hidden: isReportManager,
      },
    ];

    const defaultSorted = [
      {
        dataField: "timeSheetCode",
        order: "asc",
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <select
            name="userFilter"
            className="ts-statusFilter"
            onChange={this.handleChange}
            value={this.state.value}
          >
            <option value="All">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          {this.state.projectStatus === "Ongoing" && !isReportManager && (
            <button
              className="btn actionButton"
              size="sm"
              onClick={() => this.showModal()}
            >
              Add
            </button>
          )}
          <Modal
            backdrop="static"
            keyboard="true"
            show={this.state.showModal}
            onHide={() => this.handleModal()}
          >
            <Modal.Header className="ts-header" closeButton>
              <Modal.Title>Add Timesheet Code</Modal.Title>
            </Modal.Header>
            <AddTimesheetCode
              onClose={this.handleModal}
              projectId={this.props.projectdata._id}
            ></AddTimesheetCode>
          </Modal>

          <Modal
            backdrop="static"
            keyboard="true"
            show={this.state.showModals}
            onHide={() => this.hideEditModals()}
          >
            <Modal.Header className="ts-header" closeButton>
              <Modal.Title>Edit Timesheet Code</Modal.Title>
            </Modal.Header>
            <EditTimesheetCode
              timesheetcodedata={this.state.selectedTimesheetCode}
              close={this.closeEditModal}
              projectStatus={this.state.projectStatus}
            >
              {" "}
            </EditTimesheetCode>
          </Modal>

          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={timesheetCodes}
            defaultSortDirection="asc"
            columns={columns}
            defaultSorted={defaultSorted}
            actionButton={actionButton}
            striped
            hover
            condensed
          />

          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showmodal}
            onHide={() => this.handlemodal()}
          >
            <Modal.Header className="ts-header" closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Do you want to delete TimesheetCode "
                {rowData && rowData.timeSheetCode}" ?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-ts"
                onClick={() => {
                  this.deleteTimesheetcodes(rowData);
                  this.handlemodal();
                }}
              >
                Yes
              </button>
              <button className="btn btn-ts" onClick={() => this.handlemodal()}>
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <ToastContainer autoClose={2000} transition={Slide} limit={2} />
      </>
    );
  }
}
