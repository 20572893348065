import React, { useState } from "react";
import "./form.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api";
import moment from "moment";
import TokenService from "../tokenService";

var isReportManager = TokenService.checkReportManager();

const EditProject = ({ projectdata, onClose }) => {
  const [project, setProject] = useState({
    id: projectdata._id,
    name: projectdata.name,
    startDate: projectdata.startDate,
    endDate: projectdata.endDate,
    customername: projectdata.customerId.name,
    status: projectdata.status,
  });
  const [isDirty, setisDirty] = useState(false);

  const onChange = (e) => {
    setProject({ ...project, [e.target.name]: e.target.value });
    setisDirty(true);
  };

  const editHandler = async (e) => {
    if (
      !project.name ||
      !project.startDate ||
      !project.customername ||
      !project.status
    ) {
      return;
    }
    e.preventDefault();
    let isDeleteTimesheetCodes = false;
    if (
      projectdata.status === "Ongoing" &&
      projectdata.status !== project.status
    ) {
      isDeleteTimesheetCodes = true;
      toast.info(
        "Project status is changed to closed. All active timesheet codes will be set to inactive.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
    const { id, name, startDate, endDate, status } = project;
    const response = await api.put("/projects", {
      id,
      name,
      startDate,
      endDate,
      status,
      isDeleteTimesheetCodes,
    });
    if (response.status === 200 && onClose) {
      toast.success("Project data saved successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      onClose();
    } else if (response.status === 200) {
      toast.success("Project data saved successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      projectdata.name = project.name;
      projectdata.startDate = project.startDate;
      projectdata.endDate = project.endDate;
      projectdata.status = project.status;
    }
  };

  var defaultstartDate = moment(project.startDate).format("YYYY-MM-DD");
  var defaultendDate = project.endDate
    ? moment(project.endDate).format("YYYY-MM-DD")
    : "";

  return (
    <div className="form-container">
      <form className="form">
        <div className="input-container">
          <label htmlFor="customername" className="col-25">
            Customer *
          </label>
          <label
            htmlFor="customernameValue"
            className="col-75 edit-customer-name"
          >
            {project.customername}
          </label>
        </div>
        <div className="input-container">
          <label htmlFor="name" className="col-25">
            Project Name *
          </label>
          <input
            className="col-75"
            type="text"
            name="name"
            value={project.name}
            onChange={onChange}
            required
            disabled={isReportManager}
          />
        </div>
        <div className="input-container">
          <label htmlFor="startDate" className="col-25">
            Start Date *
          </label>
          <input
            className="col-75"
            type="date"
            name="startDate"
            defaultValue={defaultstartDate}
            onChange={onChange}
            required
            disabled={isReportManager}
          />
        </div>
        <div className="input-container">
          <label htmlFor="endDate" className="col-25">
            End Date
          </label>
          <input
            className="col-75"
            type="date"
            name="endDate"
            min={project.startDate}
            defaultValue={defaultendDate}
            onChange={onChange}
            disabled={isReportManager}
          />
        </div>
        <div className="input-container">
          <label htmlFor="status" className="col-25">
            Status *
          </label>
          <select
            className="col-75"
            name="status"
            value={project.status}
            onChange={onChange}
            disabled={isReportManager}
          >
            <option value="Ongoing">Ongoing</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
        {!isReportManager && (
          <div className="input-container">
            <button
              className="btn btn-success"
              type="submit"
              value="save"
              onClick={editHandler}
              disabled={!isDirty}
            >
              Save
            </button>
          </div>
        )}
      </form>

      <ToastContainer autoClose={2000} transition={Slide} />
    </div>
  );
};

export default EditProject;
