import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link, NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import "../index.css";
import TokenService from "../tokenService";

export default function Header() {
  const isAuthenticated = useIsAuthenticated();
  const { pathname } = useLocation();
  let isPrivilegedUser = false;
  if (TokenService.getUser() && !isPrivilegedUser) {
    let userDetail = TokenService.getUser();
    if (
      userDetail &&
      userDetail.roleId &&
      userDetail.roleId.name &&
      userDetail.roleId.name !== "User"
    )
      isPrivilegedUser = true;
  }

  let isProjectActive = (isActive) => {
    let className = "nav-link heading-font";
    if (isActive || ["/u/Projects", "/u/ProjectDetails"].includes(pathname)) {
      return className + " activeNav";
    }
    return className;
  };

  const { accounts } = useMsal();
  return (
    <>
      <Navbar bg="primary" id="nav-fixed" variant="dark">
        <Container fluid className="grid-padding">
          <Navbar.Brand href="/" className="heading-font">
            MyDay
          </Navbar.Brand>
          {isAuthenticated ? (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  {isPrivilegedUser && (
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-link heading-font activeNav"
                          : "nav-link heading-font"
                      }
                      as={Link}
                      to={"/u/Employees"}
                    >
                      Employees
                    </NavLink>
                  )}
                  <NavLink
                    className={(navData) =>
                      navData.isActive
                        ? "nav-link heading-font activeNav"
                        : "nav-link heading-font"
                    }
                    as={Link}
                    to={"/u/Timesheet"}
                  >
                    Timesheet
                  </NavLink>
                  {isPrivilegedUser && (
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-link heading-font activeNav"
                          : "nav-link heading-font"
                      }
                      as={Link}
                      to={"/u/Customers"}
                    >
                      Customers
                    </NavLink>
                  )}
                  {isPrivilegedUser && (
                    <NavLink
                      className={(navData) => isProjectActive(navData.isActive)}
                      as={Link}
                      to={"/u/Projects"}
                    >
                      Projects
                    </NavLink>
                  )}

                  {isPrivilegedUser && (
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-link heading-font activeNav"
                          : "nav-link heading-font"
                      }
                      as={Link}
                      to={"/u/Reports"}
                    >
                      Reports
                    </NavLink>
                  )}
                </Nav>
              </Navbar.Collapse>
            </>
          ) : (
            ""
          )}
          {isAuthenticated && accounts && accounts.length ? (
            <h5 className="account-name heading-font">
              Welcome {accounts[0].name}
            </h5>
          ) : (
            ""
          )}
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </Container>
      </Navbar>
      {!isAuthenticated && (
        <h5 className="card-title">
          Please sign-in to access Timesheet information.
        </h5>
      )}
    </>
  );
}
