import React from "react";
import debounce from "lodash.debounce";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../index.css";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { CloseCircleFilled } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import ChangePageTitle from "./ChangePageTitle";
import TokenService from "../tokenService";
import api from "../api";
import _ from "lodash";

const adminRoleName = "Administrator";
var pageNum = 1;
const pageSize = 200;
var allDataLoaded = false;
var roleList = [];
var isReportManager = TokenService.checkReportManager();

async function callApi(pageNum, pageSize, status) {
  let userId = TokenService.getUserId();
  var url = `/users?page=${pageNum}&limit=${pageSize}&status=${status}&userId=${userId}`;
  const response = await api.get(url);
  return response && response.data;
}

async function getRoles() {
  let userId = TokenService.getUserId();
  const response = await api.get(`/roles?userId=${userId}`);
  roleList = response && response.data;
  return roleList;
}

var status = "Inactive";
var isEnable = false;
var rowData = {};

async function disableUserById(id) {
  var url = "/users/disableUser";
  const response = await api.put(url, {
    status: status,
    isEnable: isEnable,
    _id: id,
  });
  return response.data;
}

export class ListUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      allUsers: [],
      DataisLoaded: false,
      value: "Active",
      csvFileName: "",
      roles: [],
      showModal: false,
    };

    window.onscroll = debounce(() => {
      const { loadUsers } = this;

      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >=
          document.documentElement.offsetHeight &&
        allDataLoaded === false
      ) {
        loadUsers();
      }
    }, 100);
  }

  loadUsers = () => {
    pageNum++;
    callApi(pageNum, pageSize, this.state.value).then((result) => {
      if (result && result.Users) {
        allDataLoaded = result.Users.length < pageSize;
        if (result.Users.length > 0) {
          this.setState({
            users: [...this.state.users, ...result.Users],
          });
        }
      }
    });
  };

  componentDidMount() {
    pageNum = 1;
    callApi(pageNum, pageSize, this.state.value).then((result) => {
      if (result && result.Users) {
        allDataLoaded = result.Users.length < pageSize;
        getRoles().then((userRoles) => {
          var sortedRoles = _.sortBy(userRoles, "name");
          var roles = [];
          sortedRoles.forEach((role) =>
            roles.push({ value: role.name, label: role.name })
          );
          this.setState({
            roles: roles,
          });
        });
        if (result.Users.length > 0) {
          this.setState({
            users: result.Users,
            allUsers: _.sortBy(result.Users, "displayName"),
            DataisLoaded: true,
          });
        }
      }
    });
  }

  handleChange = (event) => {
    pageNum = 1;
    allDataLoaded = false;
    this.setState({ value: event.target.value });
    callApi(pageNum, pageSize, event.target.value).then((result) => {
      if (result && result.Users) {
        allDataLoaded = result.Users.length < pageSize;
        if (result.Users && result.Users.length > 0) {
          this.setState({
            users: result.Users,
            allUsers: _.sortBy(result.Users, "displayName"),
          });
        }
      }
    });
  };

  onExportCsvClick = () => {
    this.setState({
      csvFileName:
        "EmployeesData_" +
        format(new Date(), "yyyyMMddHHmmss").toString() +
        ".csv",
    });
  };

  handleModal(row) {
    rowData = row;
    this.setState({ showModal: !this.state.showModal });
  }

  disableUser(row) {
    disableUserById(row._id).then(() => {
      var newPageSize = this.state.users.length;
      callApi(1, newPageSize, this.state.value).then((result) => {
        if (result && result.Users) {
          allDataLoaded = result.Users.length < pageSize;
          if (result.Users.length > 0) {
            this.setState({
              users: result.Users,
              DataisLoaded: true,
            });
          }
        }
      });
    });
  }

  render() {
    const { DataisLoaded, users, allUsers, csvFileName } = this.state;
    if (!DataisLoaded)
      return (
        <>
          <ChangePageTitle pageTitle="MyDay | Employees" />
          <div className="card-title">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      );

    const disableButton = (cell, row) => {
      if (row.status === "Active") {
        return (
          <div>
            <button
              className="btn-delete"
              size="sm"
              title="Disable User"
              onClick={() => this.handleModal(row)}
            >
              <CloseCircleFilled />
            </button>
          </div>
        );
      } else {
        return;
      }
    };

    const columns = [
      {
        dataField: "employeeId",
        text: "Emp ID",
        sort: true,
        align: "right",
        editable: false,
        headerClasses: "grid-header",
        headerStyle: (colum, colIndex) => {
          return { width: "7%" };
        },
      },
      {
        dataField: "displayName",
        text: "Name",
        headerClasses: "grid-header",
        sort: true,
        editable: false,
      },
      {
        dataField: "designation",
        text: "Designation",
        sort: true,
        headerClasses: "grid-header",
        editable: false,
      },
      {
        dataField: "manager",
        text: "Reporting To",
        sort: true,
        headerClasses: "grid-header",
        editable: false,
      },
      {
        dataField: "contactNumber",
        text: "Contact No.",
        align: "right",
        sort: true,
        editable: false,
        headerClasses: "grid-header",
        headerStyle: (colum, colIndex) => {
          return { width: "12%" };
        },
      },
      {
        dataField: "roleId.name",
        text: "App Role",
        sort: true,
        headerClasses: "grid-header",
        editable: () => {
          var userDetail = TokenService.getUser();
          if (userDetail && userDetail.roleId && userDetail.roleId.name) {
            return userDetail.roleId.name === adminRoleName;
          } else return false;
        },
        editor: {
          type: Type.SELECT,
          options: this.state.roles,
        },
        headerStyle: (colum, colIndex) => {
          return { width: "14%" };
        },
      },
      {
        dataField: "actions",
        text: "Action",
        isDummyField: true,
        formatter: disableButton,
        headerStyle: { width: "5%" },
        headerClasses: "grid-header",
        editable: false,
        hidden: isReportManager,
      },
    ];

    const defaultSorted = [
      {
        dataField: "displayName",
        order: "asc",
      },
    ];

    const headers = [
      { label: "Employee ID", key: "employeeId" },
      { label: "Display Name", key: "displayName" },
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Designation", key: "designation" },
      { label: "Email", key: "userName" },
      { label: "Reporting To", key: "manager" },
      { label: "Contact No.", key: "contactNumber" },
      { label: "Status", key: "status" },
    ];

    async function saveRoleChange(oldValue, newValue, row, column) {
      if (oldValue !== newValue) {
        var roleId = roleList.find((item) => item.name === row.roleId.name)._id;
        const response = await api.put("/users", {
          id: row._id,
          roleId: roleId,
        });
        return response && response.data;
      }
    }

    return (
      <>
        <ChangePageTitle pageTitle="MyDay | Employees" />
        <div className="container-fluid grid-padding">
          <select
            name="userFilter"
            className="statusFilter"
            onChange={this.handleChange}
            value={this.state.value}
          >
            <option value="All">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <CSVLink
            data={allUsers}
            headers={headers}
            filename={csvFileName}
            className="btn actionButton"
            onClick={this.onExportCsvClick}
          >
            Export CSV
          </CSVLink>
          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={users}
            columns={columns}
            defaultSortDirection="asc"
            defaultSorted={defaultSorted}
            disableButton={disableButton}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              afterSaveCell: saveRoleChange,
            })}
            striped
            hover
            condensed
          />
          <Modal
            backdrop="static"
            keyboard="true"
            show={this.state.showModal}
            onHide={() => this.handleModal()}
          >
            <Modal.Header closeButton>Warning</Modal.Header>
            <Modal.Body>
              <p>
                Do you want to disable the user '
                {rowData && rowData.displayName}' ?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-ts"
                onClick={() => {
                  this.disableUser(rowData);
                  this.handleModal();
                }}
              >
                Yes
              </button>
              <button className="btn btn-ts" onClick={() => this.handleModal()}>
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
