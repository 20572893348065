import { EncryptStorage } from "encrypt-storage";

export const encryptStorage = new EncryptStorage(
  process.env.REACT_APP_SECRET_KEY,
  {
    prefix: "@myday",
  }
);

class TokenService {
  getRefreshToken() {
    var token = encryptStorage.getItem("refreshToken");
    if (token && token !== "undefined") {
      return token;
    }
  }

  updateRefreshToken(token) {
    encryptStorage.setItem("refreshToken", token);
  }

  removeRefreshToken() {
    if (encryptStorage.getItem("refreshToken"))
      encryptStorage.removeItem("refreshToken");
  }

  getUser() {
    if (encryptStorage.getItem("userDetail")) {
      return encryptStorage.getItem("userDetail");
    }
  }

  getUserId() {
    if (encryptStorage.getItem("userDetail")) {
      return encryptStorage.getItem("userDetail")._id;
    }
  }

  setUser(user) {
    encryptStorage.setItem("userDetail", user);
  }

  removeUser() {
    if (encryptStorage.getItem("userDetail"))
      encryptStorage.removeItem("userDetail");
  }

  checkReportManager() {
    const reportManager = "Report Manager";
    var userDetail = this.getUser();
    if (userDetail && userDetail.roleId && userDetail.roleId.name) {
      return userDetail.roleId.name === reportManager;
    } else return false;
  }
}
export default new TokenService();
