import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import TokenService from "../tokenService";

const RequireAuth = ({ allowedRoles }) => {
  let userDetail = TokenService.getUser();
  const location = useLocation();

  return userDetail && allowedRoles.includes(userDetail.roleId.name) ? (
    <AuthenticatedTemplate>
      <Outlet />
    </AuthenticatedTemplate>
  ) : !userDetail ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/u/unauthorized" state={{ from: location }} replace />
  );
};

export default RequireAuth;
