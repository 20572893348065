import React from "react";
import WeekView from "./WeekView";
import ChangePageTitle from "./ChangePageTitle";

export default function Timesheet() {
  return (
    <>
      <ChangePageTitle pageTitle="MyDay | Timesheet" />
      <div className="page-margin">
        <WeekView />
      </div>
    </>
  );
}
