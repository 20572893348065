import axios from "axios";
import TokenService from "./tokenService";

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (config.data && !JSON.stringify(config.data).includes("authUserId")) {
      config.data.authUserId = TokenService.getUserId();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (
      originalConfig &&
      !originalConfig.url.includes("/validateUser") &&
      err.response
    ) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          await instance.post("/authToken/refreshToken", {
            refreshToken: TokenService.getRefreshToken(),
          });
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 403) {
        return (window.location.href = "/u/unauthorized");
      } else if (err.response.status === 405) return err.response;
    } else {
      return err && err.response ? err.response : Promise.reject(err);
    }
  }
);
export default instance;
