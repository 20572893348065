import React, { useState } from "react";
import "./form.css";
import api from "../api";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddProject = (props) => {
  const [project, setproject] = useState({
    name: "",
    startDate: "",
    endDate: "",
    customername: "",
    status: "Ongoing",
  });
  let name, value;
  const onChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setproject({ ...project, [name]: value });
  };

  const submitHandler = async (e) => {
    if (
      !project.name ||
      !project.startDate ||
      !project.customername ||
      !project.status
    ) {
      return;
    }
    e.preventDefault();
    const { name, startDate, endDate, customername, status } = project;

    const response = await api.post("/projects", {
      name,
      startDate,
      endDate,
      customername,
      status,
    });
    if (response.status === 200) {
      toast.success("Project data saved successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      props.onClose();
    }
  };

  return (
    <div className="form-container">
      <form className="form">
        <div className="input-container">
          <label htmlFor="customername" className="col-25">
            Customer *
          </label>
          <select
            className="col-75"
            name="customername"
            onChange={onChange}
            value={project.customername}
            required
          >
            <option value="" disabled hidden>
              Select an Option
            </option>
            {props.customers.map((customer) => (
              <option
                key={customer._id}
                value={customer._id}
                title={customer.name}
              >
                {customer.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <label htmlFor="name" className="col-25">
            Project Name *
          </label>
          <input
            className="col-75"
            type="text"
            name="name"
            value={project.name}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="startDate" className="col-25">
            Start Date *
          </label>
          <input
            className="col-75"
            type="date"
            name="startDate"
            value={project.startDate}
            onChange={onChange}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="endDate" className="col-25">
            End Date
          </label>
          <input
            className="col-75"
            type="date"
            name="endDate"
            min={project.startDate}
            value={project.endDate}
            onChange={onChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="status" className="col-25">
            Status *
          </label>
          <select
            className="col-75"
            name="status"
            value={project.status}
            onChange={onChange}
            required
          >
            <option value="Ongoing">Ongoing</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
        <div className="input-container">
          <button
            className="btn btn-success"
            type="submit"
            onClick={submitHandler}
            value="save"
          >
            Save
          </button>
        </div>
      </form>
      <ToastContainer autoClose={2000} transition={Slide} />
    </div>
  );
};
export default AddProject;
