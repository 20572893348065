import React from "react";
import debounce from "lodash.debounce";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../index.css";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import { EditFilled, CloseCircleFilled } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import Moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import ChangePageTitle from "./ChangePageTitle";
import api from "../api";
import TokenService from "../tokenService";
import { ToastContainer, toast, Slide } from "react-toastify";

var pageNum = 1;
const pageSize = 200;
var allDataLoaded = false;
var isReportManager = TokenService.checkReportManager();

async function callApi(pageNum, pageSize, status) {
  let userId = TokenService.getUserId();
  let url = `/customers?page=${pageNum}&limit=${pageSize}&status=${status}&userId=${userId}`;
  const response = await api.get(url);
  return response && response.data;
}

var rowData = {};

async function disablecustomerById(id) {
  var response = await api.put(`/customers/disablecustomer`, {
    _id: id,
  });
  if (response.status === 405) {
    toast.error(response.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  return response;
}

export class ListCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      allCustomers: [],
      DataisLoaded: false,
      value: "Active",
      selectedCustomer: {},
      showModal: false,
      showModals: false,
    };

    window.onscroll = debounce(() => {
      const { loadCustomers } = this;
      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >=
          document.documentElement.offsetHeight &&
        allDataLoaded === false
      ) {
        loadCustomers();
      }
    }, 100);
  }

  loadCustomers = () => {
    pageNum++;
    callApi(pageNum, pageSize, this.state.value).then((json) => {
      if (json && json.Customers) {
        allDataLoaded = json.Customers.length < pageSize;
        if (json.Customers.length > 0) {
          this.setState({
            customers: [...this.state.customers, ...json.Customers],
          });
        }
      }
    });
  };

  componentDidMount() {
    pageNum = 1;
    callApi(pageNum, pageSize, this.state.value).then((json) => {
      if (json && json.Customers) {
        allDataLoaded = json.Customers.length < pageSize;
        if (json.Customers.length > 0) {
          this.setState({
            customers: json.Customers,
            DataisLoaded: true,
          });
        }
      }
    });
  }

  handleChange = (event) => {
    pageNum = 1;
    allDataLoaded = false;
    this.setState({ value: event.target.value });
    callApi(pageNum, pageSize, event.target.value).then((json) => {
      if (json && json.Customers) {
        allDataLoaded = json.Customers.length < pageSize;
        if (json.Customers.length > 0) {
          this.setState({ customers: json.Customers });
        }
      }
    });
  };

  handleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  refreshData = () => {
    var newPageSize = this.state.customers.length + 1;
    if (newPageSize < pageSize) newPageSize = pageSize;
    callApi(1, newPageSize, this.state.value).then((json) => {
      if (json && json.Customers) {
        allDataLoaded = json.Customers.length < pageSize;
        if (json.Customers.length > 0) {
          this.setState({
            customers: json.Customers,
            DataisLoaded: true,
          });
        } else this.setState({ customers: [] });
      }
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
    this.refreshData();
  };

  closeModals = () => {
    this.setState({ showModals: false });
    this.refreshData();
  };

  handleModals(row) {
    this.setState({ selectedCustomer: row });
    this.setState({ showModals: !this.state.showModals });
  }
  hideModals() {
    this.setState({ showModals: !this.state.showModals });
  }

  handleModal1(row) {
    rowData = row;
    this.setState({ showModal1: !this.state.showModal1 });
  }

  disableCustomer(row) {
    disablecustomerById(row._id).then((result) => {
      if (result && result.status === 200) {
        this.refreshData();
        toast.success("Customer disabled successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }

  render() {
    const { DataisLoaded, customers } = this.state;
    if (!DataisLoaded)
      return (
        <>
          <ChangePageTitle pageTitle="MyDay | Customers" />
          <div className="card-title">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      );

    const ActionButton = (cell, row) => {
      return (
        <div>
          <button
            className="btn-edit"
            size="sm"
            title="Edit Customer"
            onClick={() => this.handleModals(row)}
          >
            <EditFilled />
          </button>
          {!row.isDeleted && (
            <button
              className="btn-delete"
              size="sm"
              title="Delete Customer"
              onClick={() => this.handleModal1(row)}
            >
              <CloseCircleFilled />
            </button>
          )}
        </div>
      );
    };

    const columns = [
      {
        dataField: "name",
        text: "Name",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "address1",
        text: "Address",
        headerClasses: "grid-header",
        headerStyle: { width: "30%" },
        formatter: (cell, row) => {
          return (
            <div>{`
            ${row.address1 ? row.address1 : ""} 
            ${row.address2 ? row.address2 : ""} 
            ${row.address3 ? row.address3 : ""}`}</div>
          );
        },
        sort: true,
      },
      {
        dataField: "websiteUrl",
        text: "Website",
        headerClasses: "grid-header",
        formatter: (cellContent, row) => {
          if (
            cellContent &&
            cellContent.length > 0 &&
            !~cellContent.indexOf("http")
          ) {
            cellContent = "http://" + cellContent;
          }
          return (
            <div>
              <a target="_blank" rel="noopener noreferrer" href={cellContent}>
                {cellContent}
              </a>
            </div>
          );
        },
        sort: true,
      },
      {
        dataField: "startDate",
        text: "Start Date",
        headerClasses: "grid-header",
        formatter: (cell, row) => {
          return Moment(row.startDate).format("DD-MM-YYYY");
        },
        sort: true,
      },
      {
        dataField: "endDate",
        text: "End Date",
        headerClasses: "grid-header",
        formatter: (cell, row) => {
          return row.endDate
            ? Moment(row.endDate).format("DD-MM-YYYY")
            : "Onwards";
        },
        sort: true,
      },
      {
        dataField: "actions",
        text: "Actions",
        headerClasses: "grid-header",
        formatter: ActionButton,
        hidden: isReportManager,
      },
    ];

    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ];

    return (
      <>
        <ChangePageTitle pageTitle="MyDay | Customers" />
        <div className="container-fluid grid-padding">
          <select
            name="CustomerFilter"
            className="statusFilter"
            onChange={this.handleChange}
            value={this.state.value}
          >
            <option value="All">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          {!isReportManager && (
            <button
              className="btn actionButton"
              onClick={() => this.handleModal()}
            >
              Add Customer
            </button>
          )}
          <Modal
            className="modal-container"
            backdrop="static"
            keyboard="true"
            show={this.state.showModal}
            onHide={() => this.handleModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Customer</Modal.Title>
            </Modal.Header>
            <AddCustomer onclose={this.closeModal}></AddCustomer>
          </Modal>

          <Modal
            backdrop="static"
            keyboard="true"
            show={this.state.showModals}
            onHide={() => this.hideModals()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Customer</Modal.Title>
            </Modal.Header>

            <EditCustomer
              customerdata={this.state.selectedCustomer}
              onclose={this.closeModals}
            ></EditCustomer>
          </Modal>
          <Modal
            backdrop="static"
            keyboard="false"
            show={this.state.showModal1}
            onHide={() => this.handleModal1()}
          >
            <Modal.Header closeButton>Warning</Modal.Header>
            <Modal.Body>
              <p>
                Do you want to disable the customer "{rowData && rowData.name}"
                ?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-ts"
                onClick={() => {
                  this.disableCustomer(rowData);
                  this.handleModal1();
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-ts"
                onClick={() => this.handleModal1()}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={customers}
            defaultSortDirection="asc"
            columns={columns}
            defaultSorted={defaultSorted}
            striped
            hover
            condensed
          />
        </div>
        <ToastContainer autoClose={2000} transition={Slide} />
      </>
    );
  }
}
