import React from "react";
import debounce from "lodash.debounce";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../index.css";
import AddProject from "./AddProject";
import { EditFilled, CloseCircleFilled } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import EditProject from "./EditProject";
import Moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import ChangePageTitle from "./ChangePageTitle";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api";
import TokenService from "../tokenService";

var pageNum = 1;
const pageSize = 200;
var allDataLoaded = false;
var isReportManager = TokenService.checkReportManager();

async function callApi(pageNum, pageSize, status) {
  let userId = TokenService.getUserId();
  let url = `/projects?page=${pageNum}&limit=${pageSize}&status=${status}&userId=${userId}`;
  const response = await api.get(url);
  return response && response.data;
}

async function getCustomers() {
  let userId = TokenService.getUserId();
  let url = `/customers?page=1&limit=200&status=Active&userId=${userId}`;
  const response = await api.get(url);
  return response && response.data;
}

var rowData = {};

async function deleteProjectByCode(id) {
  var response = await api.put("/projects/disableProject", { _id: id });
  if (response.status === 405) {
    toast.error(response.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  return response;
}

export class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      allProjects: [],
      DataisLoaded: false,
      value: "Ongoing",
      selectedProject: {},
      showModal: false,
      customers: [],
      addClicked: false,
      editClicked: false,
    };

    window.onscroll = debounce(() => {
      const { loadProjects } = this;

      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >=
          document.documentElement.offsetHeight &&
        allDataLoaded === false
      ) {
        loadProjects();
      }
    }, 100);
  }

  loadProjects = () => {
    pageNum++;
    callApi(pageNum, pageSize, this.state.value).then((json) => {
      if (json && json.Projects) {
        allDataLoaded = json.Projects.length < pageSize;
        if (json.Projects.length > 0) {
          this.setState({
            projects: [...this.state.projects, ...json.Projects],
          });
        }
      }
    });
  };

  componentDidMount() {
    pageNum = 1;
    callApi(pageNum, pageSize, this.state.value).then((json) => {
      if (json && json.Projects) {
        allDataLoaded = json.Projects.length < pageSize;
        if (json.Projects.length > 0) {
          this.setState({
            projects: json.Projects,
            DataisLoaded: true,
          });
        }
      }
    });
  }

  addProject = () => {
    getCustomers().then((json) => {
      if (json && json.Customers && json.Customers.length > 0)
        this.setState({ customers: json.Customers });
    });
  };

  handleChange = (event) => {
    pageNum = 1;
    allDataLoaded = false;
    this.setState({ value: event.target.value });
    callApi(pageNum, pageSize, event.target.value).then((json) => {
      if (json && json.Projects) {
        allDataLoaded = json.Projects.length < pageSize;
        if (json.Projects.length > 0)
          this.setState({ projects: json.Projects });
      }
    });
  };

  hideModals = () => {
    this.setState({ addClicked: false });
    this.setState({ editClicked: false });
  };

  refreshData = () => {
    var newPageSize = this.state.projects.length + 1;
    if (newPageSize < pageSize) newPageSize = pageSize;
    callApi(1, newPageSize, this.state.value).then((json) => {
      if (json && json.Projects) {
        allDataLoaded = json.Projects.length < pageSize;
        if (json.Projects.length > 0) {
          this.setState({
            projects: json.Projects,
            DataisLoaded: true,
          });
        } else this.setState({ projects: [] });
      }
    });
  };

  closeModal = () => {
    this.setState({ addClicked: false });
    this.setState({ editClicked: false });
    this.refreshData();
  };

  handleModal(row) {
    rowData = row;
    this.setState({ showModal: !this.state.showModal });
  }

  handleDisableModal(row) {
    rowData = row;
    this.setState({ showModal: !this.state.showModal });
  }

  handleModals(row) {
    this.setState({ selectedProject: row });
    this.setState({ editClicked: true, row });
  }

  async disableProject(row) {
    deleteProjectByCode(row._id).then((result) => {
      if (result && result.status === 200) {
        this.refreshData();
        toast.success("Project disabled successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }

  render() {
    const { DataisLoaded, projects, closeModal } = this.state;
    if (!DataisLoaded)
      return (
        <>
          <ChangePageTitle pageTitle="MyDay | Projects" />
          <div className="card-title">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      );

    const ActionButton = (cell, row) => {
      return (
        <div>
          <button
            className="btn-edit"
            size="sm"
            title="Edit Project"
            onClick={() => this.handleModals(row)}
          >
            <EditFilled />
          </button>
          <button
            id={row._id}
            className="btn-delete"
            size="sm"
            title="Delete Project"
            onClick={() => this.handleDisableModal(row)}
          >
            <CloseCircleFilled />
          </button>
        </div>
      );
    };
    const columns = [
      {
        dataField: "name",
        text: "Project Name",
        headerClasses: "grid-header",
        formatter: (cellContent, row) => (
          <div>
            <Link
              to="/u/ProjectDetails"
              state={{
                projectdata: row,
                onClose: { closeModal },
              }}
            >
              {cellContent}
            </Link>
          </div>
        ),
        sort: true,
      },
      {
        dataField: "customerId.name",
        text: "Customer Name",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "startDate",
        text: "Start Date",
        headerClasses: "grid-header",
        formatter: (cell, row) => {
          return Moment(row.startDate).format("DD-MM-YYYY");
        },
        sort: true,
      },
      {
        dataField: "endDate",
        text: "End Date",
        headerClasses: "grid-header",
        formatter: (cell, row) => {
          return row.endDate
            ? Moment(row.endDate).format("DD-MM-YYYY")
            : "Onwards";
        },
        sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "grid-header",
        sort: true,
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        headerClasses: "grid-header",
        formatter: ActionButton,
        hidden: isReportManager,
      },
    ];

    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ];

    return (
      <>
        <ChangePageTitle pageTitle="MyDay | Projects" />
        <ToastContainer autoClose={2000} transition={Slide} limit={2} />

        <div className="container-fluid grid-padding">
          <select
            name="projectFilter"
            className="statusFilter"
            onChange={this.handleChange}
            value={this.state.value}
          >
            <option value="All">All</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Closed">Closed</option>
          </select>
          {!isReportManager && (
            <button
              className="actionButton btn"
              size="sm"
              onClick={() => {
                this.addProject();
                this.setState({ addClicked: true });
              }}
            >
              Add Project
            </button>
          )}
          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={projects}
            defaultSortDirection="asc"
            columns={columns}
            defaultSorted={defaultSorted}
            ActionButton={ActionButton}
            striped
            hover
            condensed
          />
        </div>

        <Modal
          backdrop="static"
          keyboard="true"
          size="lg"
          dialogClassName="project-modal"
          show={this.state.editClicked || this.state.addClicked}
          onHide={() => this.hideModals()}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.editClicked && "Edit Project"}
              {this.state.addClicked && "Add Project"}
            </Modal.Title>
          </Modal.Header>
          {this.state.editClicked && (
            <EditProject
              projectdata={this.state.selectedProject}
              onClose={this.closeModal}
            />
          )}
          {this.state.addClicked && (
            <AddProject
              customers={this.state.customers}
              onClose={this.closeModal}
            />
          )}
        </Modal>

        <Modal
          show={this.state.showModal}
          onHide={() => this.handleModal()}
          keyboard="true"
        >
          <Modal.Header closeButton>Warning</Modal.Header>
          <Modal.Body>
            <p>
              Do you want to delete the Project "{rowData && rowData.name}" ?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-ts"
              onClick={() => {
                this.disableProject(rowData);
                this.handleModal();
              }}
            >
              Yes
            </button>
            <button className="btn btn-ts" onClick={() => this.handleModal()}>
              No
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
